<template>
    <div class="information per-main">
        <a-form-model :model="form" ref="ruleForm" :rules="rules">
            <a-form-model-item >
                <div class="form-item">
                    <div class="label">头像</div>
                    <div class="input">
                        <a-avatar :size="100" :src="form.ImageUrl" >
                            <svg-icon class="icon_user" icon-class="icon_user2" v-if="!form.ImageUrl"></svg-icon>
                        </a-avatar>
                        <div class="loading" v-if="imgLoading">
                            <a-icon type='loading'  />
                            <div class="ant-upload-text">上传中</div>
                        </div>
                        <a-input v-model="form.ImageUrl"  hidden/>
                        <div class="handle edit">
                            <a-upload
                                name="avatar"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                :action="action"
                                :before-upload="beforeUpload"
                                @change="handleChange"
                                accept=".jpg,.png,.gif,.jpeg"
                                :customRequest="customRequest"
                            >
                                <span>修改</span>
                            </a-upload>
                        </div>
                    </div>
                </div>
            </a-form-model-item>
            <a-form-model-item >
                <div class="form-item">
                    <div class="label">账号</div>
                    <div class="input">
                        <a-input v-model="form.LoginName" disabled/>
                        <div class="handle">默认为注册邮箱/手机号</div>
                    </div>
                    
                </div>
            </a-form-model-item>
            <a-form-model-item >
                <div class="form-item">
                    <div class="label">昵称</div>
                    <div class="input">
                        <a-input v-model="form.Nickname"/>
                        <!-- <div class="handle">默认为注册邮箱/手机号</div> -->
                    </div>
                    
                </div>
            </a-form-model-item>
            <a-form-model-item >
                <div class="form-item">
                    <div class="label">职称</div>
                    <div class="input">
                        <a-input v-model="form.Title"/>
                        <!-- <div class="handle">默认为注册邮箱/手机号</div> -->
                    </div>
                    
                </div>
            </a-form-model-item>
            <a-form-model-item prop="Email"> 
                <div class="form-item" >
                    <div class="label">邮箱</div>
                    <div class="input">
                        <a-input v-model="form.Email" />
                    </div>
                    
                </div>
            </a-form-model-item>
            <a-form-model-item prop="Phone">
                <div class="form-item" >
                    <div class="label">手机号</div>
                    <div class="input">
                        <a-input disabled v-model="form.Phone" />
                        <div class="handle edit" @click="$router.push('/phone')"><span>修改</span></div>
                    </div>
                </div>
            </a-form-model-item>
            <a-form-model-item >
                <div class="form-item">
                    <div class="label">关联微信</div>
                    <div class="input">
                        <a-input v-model="form.OpendId" disabled/>
                        <div class="handle edit">
                            <span v-if="!form.OpendId" @click="openWechatDialog">绑定</span>
                            <span v-if="form.OpendId" @click="openWechatDialog">修改</span>&nbsp;  
                            <a-popconfirm placement="top" ok-text="是" cancel-text="否" @confirm="unBindWechat">
                                <template slot="title">确定解绑微信？</template>
                                <span v-if="form.OpendId">解绑</span>
                            </a-popconfirm>
                            
                        </div>
                    </div>
                </div>
            </a-form-model-item>
            <a-form-model-item >
                <div class="form-item">
                    <div class="label">&nbsp;</div>
                    <div class="input btn" >
                        <a href="javascript:void(0);" class="logoff" @click="logOfff">注销账号</a>
                        <a-button @click="save">保存</a-button>
                    </div>
                </div>
            </a-form-model-item>
        </a-form-model>
        <a-modal
            title="绑定微信"
            :visible="visible"
            :footer="null"
            @cancel="closeWechatDialog"
        >   
            <div class="dialog_content">
                <div class="wechat">
                    <wxlogin
                        v-if="state"
                        :appid="appid"
                        :scope="'snsapi_login'"
                        :theme="'black'"
                        self_redirect="true"
                        :redirect_uri="encodeURIComponent(self_redirect)"
                        rel="external nofollow"
                        :state="state"
                    />
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { Base64 } from 'js-base64'
import { getOSSUpload } from '@/api/common'
import { UpdateMemberInfo , GetBindByState , UnbindWeiXin } from '@/api/user'
import axios from 'axios'
import wxlogin from "vue-wxlogin";
export default {
    metaInfo() {
		return {
			title: '个人基本信息-个人中心-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: '个人基本信息-个人中心-文物出版社'
                },
                {
                    name: 'description',
					content: '个人基本信息-个人中心-文物出版社'
                }
            ]
		}
	},
    components:{
        wxlogin
    },
    data(){
        let validatePhone = (rule, value, callback) => {
            const regMobile =
                /^0?(13[0-9]|14[1-9]|15[012356789]|16[56]|17[0-9]|18[0-9]|19[189])[0-9]{8}$/;
            if (value === '') {
                callback(new Error('请输入手机号'))
            } else if (regMobile.test(value)) {
                callback()
                return
            } else {
                callback(new Error('请输入手机号'))
            }
        }
        let validateMail = (rule, value, callback) => {
            const regEmail = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
            if (value === '') {
                callback(new Error('请输入正确邮箱！'))
            } else if (regEmail.test(value)) {
                callback()
                return
            } else {
                callback(new Error('请输入正确邮箱！'))
            }
        }
        return{
            form:{},
            action:window.configObj.ossURL,
            imgLoading:false,
            rules:{
                Email: [
                    { validator: validateMail,trigger: 'change' }
                ],
                Phone: [ 
                    { validator: validatePhone,trigger: 'change' }
                ]
            },
            // 微信二维码弹窗
            visible:false, 
            state:'',
            timer:null,
            appid:window.configObj.appid,
            self_redirect:window.configObj.changeWechatUrl
        }
    },
    computed:{
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },
    watch:{
        userInfo: {
            handler(newVal,oldVal){
                this.form = JSON.parse(JSON.stringify(newVal))
            },
            immediate: true,
        }
    },
    methods:{
        beforeUpload(file){
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('请上传小于2M的图片！');
            }
            return isLt2M;
        },
        async customRequest(file){
            this.imgLoading = true
            const fileObj = file.file // 相当于input里取得的files
            const fd = new FormData() // FormData 对象
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            await getOSSUpload({ fileName: fileObj.name }).then(res => {
                console.log(res);
                if (res.code === 200) { 
                    fd.append('key', res.data.key)
                    fd.append('policy', res.data.policy)
                    fd.append('OSSAccessKeyId', res.data.accessid)
                    fd.append('success_action_status', '200') // 让服务端返回200,不然，默认会返回204
                    fd.append('callback', res.data.callback)
                    fd.append('signature', res.data.signature)
                    fd.append('file', fileObj) // 文件对象
                    axios.post(this.action, fd, config).then((res) => {
                        console.log(res);
                        this.form.ImageUrl = res.data.location
                        setTimeout(() => {
                            this.imgLoading = false
                        },200)
                    }).catch(err => {
                        console.log(err);
                        this.imgLoading = false
                    })
                }
            }).catch(err => {
                console.log(err);
            })
            
        },
        handleChange(info){
            console.log(info);
        },
        // 保存
        save(){
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    UpdateMemberInfo(Base64.encode(JSON.stringify(this.form))).then(res => {
                        console.log(res);
                        this.$message.success(res.msg)
                        this.$store.dispatch('user/getUserInfo')
                    }).catch(err => {
                        console.log(err);
                    })
                } else {
                    return false;
                }
            });
        },
        // 
        openWechatDialog(){
            this.visible = true
            this.getUrl()
            this.timer = setInterval(() => {
                GetBindByState({state:this.state}).then(res => {
                    if(res.code == 0) return
                    this.visible = false
                    this.$message.success(res.msg)
                    this.state = ''
                    clearInterval(this.timer)
                    this.timer = null
                    this.$store.dispatch('user/getUserInfo')
                }).catch(err => {
                    console.log(err);
                    this.state = ''
                    this.getUrl()
                })
            },1000)
        },
        closeWechatDialog(){
            this.visible = false
            this.state = ''
            clearInterval(this.timer)
            this.timer = null
        },
        getUrl(){
            var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
            this.state = guid
        },
        unBindWechat(){
            UnbindWeiXin().then(res => {
                this.$message.success(res.msg)
                this.$store.dispatch('user/getUserInfo')
            }).catch(err=> err)
        },
        logOfff(){
            this.$store.dispatch('user/logOff')
        }
    },
}
</script>
<style lang="less" scoped>
.information{
    display: flex;
    justify-content: center;
    padding: 30px 140px 0 0 ;
    :deep(.ant-input){
        width: 500px;
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        font-size: 16px;
        color: rgb(85, 85, 85);
    }
    :deep(.ant-input-disabled){
        background-color: #eeeeee;
        border-color: #eeeeee !important;
    }
}
.form-item{
    display: flex;
    align-items: center;
    position: relative;
    .label{
        width: 65PX;
        text-align: justify;
        text-align-last: justify;
        margin-right: 45px;
        font-size: 16px;
        color: rgb(51, 51, 51);
        font-weight: bold;
    }
    .input{
        position: relative;
    }
}
.icon_user{
    font-size: 100px !important;
}
:deep(.ant-avatar){
    background-color: #fff;
    position: relative;
    .ant-avatar-string{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0 ;
        top: 0;
        transform: scale(1) translateX(0) !important;
    }

}
.loading{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0 ;
    top: 0;
    z-index: 3;
    background-color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    color: #fff;
}
.anticon-loading{
    line-height: 1;
}
.ant-upload-text{
    line-height: 1;
    font-size: 14px;
    margin-top: 5px;
}
.handle{
    position: absolute;
    left: calc(100% + 30px);
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    font-size: 16px;
    color: rgb(136, 136, 136);
    &.edit{
        
        span{
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

:deep(.avatar-uploader){
    .ant-upload{
        width: auto;
        height: auto;
        white-space: nowrap;
        margin: 0 !important;
        line-height: 1;
        border: none;
        background-color: transparent;
        font-size: 16px;
        padding: 0 !important;
        color: rgb(136, 136, 136);
        text-decoration: underline;
    }
}
.btn{
    flex: 1;
    text-align: center;
    margin-top: 50px;
    .ant-btn{
        min-width: 200px;
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
        border-radius: 25px;
        background-color: #00ffc0;
        border-color: #00ffc0;
        font-size: 0.18rem;
        color: rgb(0, 0, 0);
        &:focus,
        &:hover {
            background-color: #79f9d9;
            border-color: #79f9d9;
            color: #000;
        }
        &:active {
            background-color: #00ff7e;
            border-color: #00ff7e;
            color: #000;
        }
    }
}
:deep(.ant-form-explain){
    padding-left: calc( 65Px + 45px);
}
.wechat{
    width: 300Px;
}
.dialog_content{
    display: flex;
    justify-content: center;
}
.logoff{
    margin-right: 50px;
    text-decoration: underline;
    &:hover{
        color: #00ffc0;
    }
}
</style>